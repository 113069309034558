import { ReactElement, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SingleValue } from "react-select";
import styled from "styled-components";
import {
    ActionItems,
    ActionItemTypes,
    Common,
    Nodes,
} from "../../../core/constants/translation-namespace";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";
import useLoader from "../../../core/hooks/loaderManager";
import {
    createNavigateSearchParameter,
    useNavigateSearch,
} from "../../../core/hooks/navigateSearch";
import { useAuth } from "../../../core/store/auth-context";
import { useMenu } from "../../../core/store/menu-context";
import {
    ContentContainer,
    DetailsLabel,
    DetailsValue,
    EndAlignedDiv,
    LargeVerticalSpace,
    maxContentWidthSelectStyle,
    PageHeading,
    PageSubHeading,
    SectionVerticalSpace,
    StyledFormLabel,
    StyledHiddenOverflowForm,
} from "../../../core/theme/global-styles";
import {
    convertUtcDateToLocalDate,
    toDateAndTimeFormat,
} from "../../../core/utilities/date-helper";
import { getEnumsForType } from "../../../core/utilities/enum-helper";
import { DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import { actionItemDynamicFieldValueTypeBooleanDropdownItems } from "../../../core/utilities/filterDropdownItems";
import { getPath } from "../../../core/utilities/getPath";
import {
    areQueriesLoading,
    areQueriesSuccessful,
    isMutationLoading,
    isQueryLoading,
    isQuerySuccessful,
} from "../../../core/utilities/responseStateHelper";
import { ActionItemTypeDto } from "../../../domain/dtos/action-items/action-item-type-dto";
import { CreateActionItemDto } from "../../../domain/dtos/actions/create-action-item-dto";
import {
    convertNotificationTriggersDtosToNotificationTriggersToggleDtos,
    NotificationTriggerToggleDto,
} from "../../../domain/dtos/actions/notification-trigger-toggle-dto";
import { RaciParticipantDto } from "../../../domain/dtos/actions/raci-participant-dto";
import { createBasePaginationDto } from "../../../domain/dtos/common/base-pagination-dto";
import { createPaginationDto } from "../../../domain/dtos/common/pagination-dto";
import {
    createReactSelectDto,
    ReactSelectDto,
    valuesToReactSelectDtos,
} from "../../../domain/dtos/common/react-select-dto";
import { createBaseUserDto } from "../../../domain/dtos/users/base-user-dto";
import { ActionItemImportance } from "../../../domain/enums/action-items/ActionItemImportance";
import { ActionItemNodeAssociationMode } from "../../../domain/enums/action-items/ActionItemNodeAssociationMode";
import RaciRelationship from "../../../domain/enums/action-items/RaciRelationship";
import AnswerValueType from "../../../domain/enums/answer-types/answer-value-type";
import { TriggeringDate } from "../../../domain/enums/notification-triggers/triggering-date";
import { PeriodType } from "../../../domain/enums/PeriodType";
import { ActionItemResponse } from "../../../domain/responses/actions/action-item-response";
import { Response } from "../../../domain/responses/common/response-response";
import {
    useCreateActionItem,
    useFilterAssociatedRaciSetUsers,
    useFilterUsers,
    useGetActionItemDetails,
} from "../../../domain/viewmodels/actions/clone-action-item-viewmodel";
import SbCheckField from "../../atoms/input/SbCheckField";
import SbNumberBox from "../../atoms/input/SbNumberBox";
import { SbSelect } from "../../atoms/input/SbSelect";
import { SbAlert } from "../../atoms/SbAlert";
import { AssociateButton, CancelButton, SbButton } from "../../atoms/SbButton";
import SbLabelText from "../../atoms/SbLabelText";
import { translateText } from "../../helpers/translate";
import { translateWithFallback } from "../../helpers/translate-with-common-fallback";
import SbFormCheckFieldGroup from "../../molecules/input/SbFormCheckFieldGroup";
import SbFormDateTimeFieldGroup from "../../molecules/input/SbFormDateTimeFieldGroup";
import SbFormNumberBoxGroup from "../../molecules/input/SbFormNumberBoxGroup";
import { SbFormSelectFieldGroup } from "../../molecules/input/SbFormSelectFieldGroup";
import SbFormTextAreaGroup from "../../molecules/input/SbFormTextAreaGroup";
import SbFormTextFieldGroup from "../../molecules/input/SbFormTextFieldGroup";
import SbModal, { ModalSize } from "../../molecules/SbModal";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { RaciParticipant } from "../../organisms/RaciParticipant";
import { AssignToHierarchyComponent } from "../action-items/AssignToHierarchyComponent";

const StyledCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const CloneActionItemContainer: React.FC = () => {
    const menu = useMenu();
    const auth = useAuth();
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();

    const [userSearchText, setUserSearchText] = useState<string | null>(null);
    const [raciParticipants, setRaciParticipants] = useState<RaciParticipantDto[] | null>([]);
    const [actionItemTypeNode, setActionItemTypeNode] = useState<ActionItemTypeDto | null>(null);
    const [actionItemSubTypeNode, setActionItemSubTypeNode] = useState<ActionItemTypeDto | null>();
    const [actionItemImportance, setActionItemImportance] = useState<ActionItemImportance | null>(
        null
    );
    const [notificationTriggersToggle, setNotificationTriggersToggle] = useState<
        NotificationTriggerToggleDto[] | null
    >(null);
    const [selectedNode, setSelectedNode] = useState<number | undefined>(undefined);
    const [showAssociateNodeModal, setAssociateNodeModal] = useState(false);
    const [createActionItemDto, setCreateActionItemDto] = useState<CreateActionItemDto>();

    const actionItemId = Number(useParams().actionItemId);
    const [urlSearchParams] = useSearchParams();
    const actionPlanId = urlSearchParams.get("actionPlanId")
        ? Number(urlSearchParams.get("actionPlanId"))
        : undefined;

    const getActionItemDetails = useGetActionItemDetails(
        actionItemId,
        actionItemTypeNode?.nodeId!,
        createBasePaginationDto(1, 1000)
    );

    const getActionItemTypes = getActionItemDetails[0];
    const getActionItemSubTypes = getActionItemDetails[1];
    const actionItemDetails = getActionItemDetails[2];
    const getAdditionalFieldValues = getActionItemDetails[3];
    const getAdditionalFields = getActionItemDetails[4];
    const getNotificationTriggers = getActionItemDetails[5];
    const getFilterUsers = useFilterUsers(
        createPaginationDto(1, 1000, null, false),
        userSearchText
    );
    const getRaciSetUsers = useFilterAssociatedRaciSetUsers(
        actionItemId,
        createBasePaginationDto(1, 100)
    );

    const authUser = createBaseUserDto(Number(auth.userId), "", "", auth.email, auth.userName, "");

    const getActionItemTypesResponseData = getActionItemTypes.data;
    const getActionItemSubTypesResponseData = getActionItemSubTypes.data;
    const actionItemDetailsResponseData = actionItemDetails.data;
    const getAdditionalFieldValuesResponseData = getAdditionalFieldValues.data;
    const getAdditionalFieldsResponseData = getAdditionalFields.data;
    const getFilterUsersResponseData = getFilterUsers.data;
    const getRaciSetUsersResponseData = getRaciSetUsers.data;
    const getNotificationTriggersResponseData = getNotificationTriggers.data;

    const createActionItem = useCreateActionItem();

    const { t } = useTranslation("translation", { keyPrefix: ActionItems });

    useLoader(
        areQueriesLoading([
            getActionItemTypes,
            getActionItemSubTypes,
            actionItemDetails,
            getAdditionalFieldValues,
            getAdditionalFields,
            getNotificationTriggers,
        ]) || isMutationLoading(createActionItem),
        CloneActionItemContainer
    );

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.ActionTracker);
        menu.changeActiveDrawerItem(DrawerTitles.ActionItems);
    }, []);

    useEffect(() => {
        if (isQuerySuccessful(actionItemDetails)) {
            setActionItemImportance(actionItemDetailsResponseData!.actionItemImportance);
            setActionItemTypeNode(actionItemDetailsResponseData!.actionItemTypeDto);
            setActionItemSubTypeNode(actionItemDetailsResponseData!.actionItemSubTypeDto);
            setNotificationTriggersToggle(
                convertNotificationTriggersDtosToNotificationTriggersToggleDtos(
                    getNotificationTriggersResponseData!
                )
            );
        }
    }, [actionItemDetails.fetchStatus]);

    useEffect(() => {
        if (isQuerySuccessful(getRaciSetUsers)) {
            setRaciParticipants(
                RaciParticipantDto.toRaciParticipantDtos(getRaciSetUsersResponseData!)
            );
        }
    }, [getRaciSetUsers.fetchStatus]);

    const handleSaveActionItem = (): void => {
        const actionItem: CreateActionItemDto = {
            ...createActionItemDto!,
            organisationalNodeId: selectedNode!,
        };

        setCreateActionItemDto(actionItem);

        createActionItem.mutate(actionItem!, {
            onSuccess: async (response: Response<ActionItemResponse>) => {
                const actionItemId = response.data.actionItemId;
                const params = [
                    createNavigateSearchParameter("success", "true"),
                    createNavigateSearchParameter(
                        "messageKey",
                        "SuccessfullyClonedActionItemAndAssociatedOrganisationalNode"
                    ),
                ];

                navigateSearch(`${getPath(DrawerTitles.ActionItems)}/${actionItemId}`, params);
            },
            onError: errorResponseToDisplayHandler,
        });
    };

    const handleShowAssociateNodeModal = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        setAssociateNodeModal(true);
        setCreateActionItemDto(
            CreateActionItemDto.createActionItemDtoForCloneDto(
                formData,
                actionItemImportance!,
                actionItemSubTypeNode!.nodeId,
                getAdditionalFieldsResponseData!,
                raciParticipants!,
                actionPlanId,
                getNotificationTriggersResponseData!,
                actionItemTypeNode?.nodeId! !== actionItemDetailsResponseData!.actionItemTypeNodeId,
                selectedNode!,
                ActionItemNodeAssociationMode.Organisational
            )
        );
    };

    const onActionItemImportanceSelected = (
        option: SingleValue<ReactSelectDto<ActionItemImportance>>
    ): void => setActionItemImportance(option!.value);

    const onChangeActionTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemTypeNode(option!);
        setActionItemSubTypeNode(null);
    };

    const onChangeActionSubTypeValue = (option: SingleValue<ActionItemTypeDto>): void => {
        setActionItemSubTypeNode(option!);
    };

    const onAddRaciParticipant = (): void => {
        const allRaciParticipantsArray = [...raciParticipants!];

        const maxIndex = Math.max(
            ...allRaciParticipantsArray.map((x) => x.index ?? allRaciParticipantsArray.length)
        );

        allRaciParticipantsArray.push(
            new RaciParticipantDto(maxIndex + 1, RaciRelationship.Responsible, null, false)
        );

        allRaciParticipantsArray.sort((a, b) => (a.index > b.index ? 1 : -1));
        setRaciParticipants(allRaciParticipantsArray);
    };

    const onRemoveRaciParticipant = (index: number): void => {
        const allRaciParticipantsArray = [...raciParticipants!];
        const filteredRaciParticipantsArray = allRaciParticipantsArray.filter(
            (x) => x.index != index
        );
        filteredRaciParticipantsArray.sort((a, b) => (a.index > b.index ? 1 : -1));
        setRaciParticipants(filteredRaciParticipantsArray);
    };

    const onUpdateRaciParticipant = (index: number, raciRelationship: RaciRelationship): void => {
        const allRaciParticipantsArray = [...raciParticipants!];
        allRaciParticipantsArray.find((x) => x.index === index)!.raciRelationship =
            raciRelationship;
        allRaciParticipantsArray.sort((a, b) => (a.index > b.index ? 1 : -1));
        setRaciParticipants(allRaciParticipantsArray);
    };

    const onNotificationTriggerEnableToggle = (
        checked: boolean,
        notificationTriggerId: number
    ): void => {
        const notificationTriggersToggleArray = [...notificationTriggersToggle!];
        notificationTriggersToggleArray!.find(
            (x) => x.notificationTriggerId === notificationTriggerId
        )!.isEnabled = checked;

        notificationTriggersToggleArray.sort((a, b) =>
            a.notificationTriggerId > b.notificationTriggerId ? 1 : -1
        );

        setNotificationTriggersToggle(notificationTriggersToggleArray);
    };

    const renderActionItemDetail = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemDetails")}>
            <>
                <SbFormTextFieldGroup
                    name="name"
                    maxLength={255}
                    label={t("Name", { keyPrefix: Common })}
                    type="text"
                    required
                    defaultValue={actionItemDetailsResponseData?.name}
                />

                <SbFormTextAreaGroup
                    name="description"
                    label={t("Description", { keyPrefix: Common })}
                    maxLength={1500}
                    required
                    rows={4}
                    defaultValue={actionItemDetailsResponseData?.description}
                />

                <SbFormSelectFieldGroup
                    name={"actionItemImportance"}
                    label={t("ActionItemUrgency")}
                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                    required
                    searchable={false}
                    clearable={false}
                    items={getEnumsForType(ActionItemImportance).map((x) =>
                        createReactSelectDto(x, ActionItemImportance[x])
                    )}
                    onChange={onActionItemImportanceSelected}
                    itemDisplayText={(option: ReactSelectDto<ActionItemImportance>) =>
                        t(ActionItemImportance[option.value], { keyPrefix: Common })
                    }
                    defaultSelectedItem={createReactSelectDto(
                        actionItemDetailsResponseData!.actionItemImportance,
                        PeriodType[actionItemDetailsResponseData!.actionItemImportance]
                    )}
                />

                <SbFormCheckFieldGroup
                    fieldLabel={t("ExcludeFromReporting")}
                    type="checkbox"
                    values={[
                        {
                            name: "isPersonalActionItem",
                            defaultSelected: actionItemDetailsResponseData?.isPersonalActionItem,
                        },
                    ]}
                />

                <SbFormDateTimeFieldGroup
                    name="startDate"
                    label={t("StartDate")}
                    required
                    defaultValue={
                        actionItemDetailsResponseData!.actionInstanceDetailsDto.startDateLocal
                    }
                />

                <SbFormDateTimeFieldGroup
                    name="dueDate"
                    label={t("DueDate")}
                    required
                    defaultValue={
                        actionItemDetailsResponseData!.actionInstanceDetailsDto.dueDateLocal
                    }
                />
            </>
        </TextTitledPanel>
    );

    const renderActionItemTypes = (): JSX.Element => (
        <TextTitledPanel title={t("ActionItemType", { keyPrefix: ActionItemTypes })}>
            <SbFormSelectFieldGroup
                name={"actionItemType"}
                label={t("ActionItemType")}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemTypesResponseData} //TODO: Add translations for dynamic data
                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                onChange={onChangeActionTypeValue}
                defaultSelectedItem={actionItemTypeNode}
            />

            <SbFormSelectFieldGroup
                name={"actionItemSubType"}
                label={t("ActionItemSubType", { keyPrefix: ActionItemTypes })}
                placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                required
                searchable
                clearable={false}
                items={getActionItemSubTypesResponseData} //TODO: Add translations for dynamic data
                itemDisplayText={(option: ActionItemTypeDto) => option.value}
                onChange={onChangeActionSubTypeValue}
                defaultSelectedItem={actionItemDetailsResponseData?.actionItemSubTypeDto}
                value={actionItemSubTypeNode}
            />
        </TextTitledPanel>
    );

    const renderRaciParticipants = (): JSX.Element => (
        <TextTitledPanel title={t("RaciParticipants")}>
            {getRaciSetUsers && (
                <RaciParticipant
                    raciParticipants={raciParticipants!}
                    userList={getFilterUsersResponseData!}
                    isLoading={isQueryLoading(getFilterUsers)}
                    onAddRaciParticipant={onAddRaciParticipant}
                    onRemoveRaciParticipant={onRemoveRaciParticipant}
                    onUpdateRaciParticipant={onUpdateRaciParticipant}
                    setUserSearchText={setUserSearchText}
                    authUserDto={authUser}
                />
            )}
        </TextTitledPanel>
    );

    const renderExistingAdditionalInformation = (): JSX.Element => (
        <>
            <TextTitledPanel title={t("AdditionalInformation")}>
                {getAdditionalFieldValuesResponseData?.map((x) => {
                    switch (x.actionItemDynamicFieldDetailsDto.valueType) {
                        case AnswerValueType.Text:
                            return (
                                <SbFormTextFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    type={"text"}
                                    maxLength={1000}
                                    defaultValue={x.value}
                                />
                            );

                        case AnswerValueType.TextArea:
                            return (
                                <SbFormTextAreaGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    maxLength={3000}
                                    rows={4}
                                    defaultValue={x.value}
                                />
                            );

                        case AnswerValueType.Boolean:
                            return (
                                <SbFormSelectFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    placeholderText={t("NotSet", { keyPrefix: Nodes })}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(
                                        actionItemDynamicFieldValueTypeBooleanDropdownItems
                                    )}
                                    itemDisplayText={(option: ReactSelectDto<string>) =>
                                        translateWithFallback(t, option.value, Nodes)
                                    }
                                    defaultSelectedItem={valuesToReactSelectDtos(
                                        actionItemDynamicFieldValueTypeBooleanDropdownItems
                                    ).find((y) => y.value === x.value)}
                                />
                            );

                        case AnswerValueType.DateTime:
                            return (
                                <SbFormDateTimeFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    defaultValue={
                                        x.value
                                            ? convertUtcDateToLocalDate(new Date(x.value))
                                            : null
                                    }
                                />
                            );

                        case AnswerValueType.List:
                            return (
                                <SbFormSelectFieldGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    searchable={false}
                                    clearable={false}
                                    items={valuesToReactSelectDtos(
                                        x.actionItemDynamicFieldDetailsDto.listData.split("|")
                                    )}
                                    itemDisplayText={
                                        (option: ReactSelectDto<string>) => option.value //TODO: Add translations for dynamic data
                                    }
                                    defaultSelectedItem={valuesToReactSelectDtos(
                                        x.actionItemDynamicFieldDetailsDto.listData.split("|")
                                    ).find((y) => y.value === x.value)}
                                />
                            );

                        case AnswerValueType.Integer:
                        case AnswerValueType.Decimal:
                            return (
                                <SbFormNumberBoxGroup
                                    name={`existing${x.actionItemDynamicFieldId.toString()}`}
                                    required={x.actionItemDynamicFieldDetailsDto.isRequired}
                                    label={x.actionItemDynamicFieldDetailsDto.fieldName}
                                    defaultValue={x.value}
                                    value={x.value}
                                />
                            );
                    }
                })}
            </TextTitledPanel>
            <SectionVerticalSpace />
        </>
    );

    const renderAdditionalInformation = (): JSX.Element => (
        <>
            <TextTitledPanel title={t("AdditionalInformation")}>
                {getAdditionalFieldsResponseData &&
                    getAdditionalFieldsResponseData?.map((x) => {
                        switch (x.valueType) {
                            case AnswerValueType.Text:
                                return (
                                    <SbFormTextFieldGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        label={x.fieldName}
                                        required={x.isRequired}
                                        maxLength={1000}
                                        type={"text"}
                                    />
                                );

                            case AnswerValueType.TextArea:
                                return (
                                    <SbFormTextAreaGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        label={x.fieldName}
                                        required={x.isRequired}
                                        maxLength={3000}
                                        rows={4}
                                    />
                                );

                            case AnswerValueType.Boolean:
                                return (
                                    <SbFormSelectFieldGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        label={x.fieldName}
                                        placeholderText={t("NotSet", { keyPrefix: Nodes })}
                                        required={x.isRequired}
                                        searchable={false}
                                        clearable={false}
                                        items={valuesToReactSelectDtos(
                                            actionItemDynamicFieldValueTypeBooleanDropdownItems
                                        )}
                                        itemDisplayText={(option: ReactSelectDto<string>) =>
                                            translateWithFallback(t, option.value, Nodes)
                                        }
                                    />
                                );

                            case AnswerValueType.DateTime:
                                return (
                                    <SbFormDateTimeFieldGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        label={x.fieldName}
                                        required={x.isRequired}
                                    />
                                );

                            case AnswerValueType.List:
                                return (
                                    <SbFormSelectFieldGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        label={x.fieldName}
                                        placeholderText={t("PleaseSelect", { keyPrefix: Common })!}
                                        required={x.isRequired}
                                        searchable={false}
                                        clearable={false}
                                        items={valuesToReactSelectDtos(x.listData.split("|"))}
                                        itemDisplayText={
                                            (option: ReactSelectDto<string>) => option.value //TODO: Add translations for dynamic data
                                        }
                                    />
                                );

                            case AnswerValueType.Integer:
                            case AnswerValueType.Decimal:
                                return (
                                    <SbFormNumberBoxGroup
                                        name={x.actionItemDynamicFieldId.toString()}
                                        required={x.isRequired}
                                        label={x.fieldName}
                                    />
                                );
                        }
                    })}
            </TextTitledPanel>
            <SectionVerticalSpace />
        </>
    );

    const renderOptionalInformation = (): JSX.Element => (
        <TextTitledPanel title={t("OptionalInformation")}>
            <SbFormTextAreaGroup
                name={"requiredOutcome"}
                label={t("RemedialAction")}
                maxLength={4000}
                rows={4}
                defaultValue={actionItemDetailsResponseData?.requiredOutcome}
            />
        </TextTitledPanel>
    );

    const renderNotificationTriggers = (): JSX.Element => (
        <TextTitledPanel title={t("NotificationTriggers")}>
            {notificationTriggersToggle &&
                notificationTriggersToggle?.map((x) => (
                    <Form.Group as={Row} className="mb-3">
                        <StyledFormLabel column sm={2}>
                            <SbLabelText label={x.name} />
                        </StyledFormLabel>
                        <StyledCol md="auto">
                            <SbCheckField
                                type="checkbox"
                                name={`isEnabled${x.notificationTriggerId}`}
                                defaultSelected
                                onChangeHandler={(_: string, checked: boolean) => {
                                    onNotificationTriggerEnableToggle(
                                        checked,
                                        x.notificationTriggerId
                                    );
                                }}
                            />
                        </StyledCol>

                        {x.isEnabled && (
                            <>
                                <Col md={1}>
                                    <SbNumberBox
                                        name={`sendWindowInMinutes${x.notificationTriggerId}`}
                                        defaultValue={x.triggerTimeDeltaIntervalDto.value}
                                    />
                                </Col>
                                <Col md={1}>
                                    <SbSelect
                                        name={`sendWindowInMinutesPeriodType${x.notificationTriggerId}`}
                                        searchable={false}
                                        clearable={false}
                                        styles={maxContentWidthSelectStyle}
                                        defaultSelectedItem={createReactSelectDto(
                                            PeriodType.Days,
                                            PeriodType[PeriodType.Days]
                                        )}
                                        items={getEnumsForType(PeriodType).map((x) =>
                                            createReactSelectDto(x, PeriodType[x])
                                        )}
                                        itemLabel={(option: ReactSelectDto<PeriodType>) =>
                                            translateText(t, option.label, Common)
                                        }
                                        itemValue={(option: ReactSelectDto<PeriodType>) =>
                                            option.value.toString()
                                        }
                                    />
                                </Col>
                                <Col md={1}>
                                    {x.triggerBefore
                                        ? t("Before", { keyPrefix: Common })
                                        : t("After", { keyPrefix: Common })}{" "}
                                    {TriggeringDate[x.triggeringDate].toString()}
                                </Col>
                            </>
                        )}
                    </Form.Group>
                ))}
        </TextTitledPanel>
    );

    const renderActionButtons = (): JSX.Element => (
        <EndAlignedDiv>
            <SbButton
                type={"submit"}
                variant={"primary"}
                label={t("Next", { keyPrefix: Common })}
                icon={FaArrowRight}
            />
            <CancelButton onClick={() => navigate(-1)} />
        </EndAlignedDiv>
    );

    const renderActionItemDetails = (): JSX.Element => (
        <ContentContainer>
            <Row>
                <Col md="auto">
                    <DetailsLabel>{t("ActionItem", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("Description", { keyPrefix: Common })}</DetailsLabel>
                    <DetailsLabel>{t("StartDate", { keyPrefix: ActionItems })}</DetailsLabel>
                    <DetailsLabel>{t("DueDate", { keyPrefix: ActionItems })}</DetailsLabel>
                </Col>
                <Col md="auto">
                    <DetailsValue>{createActionItemDto?.name}</DetailsValue>
                    <DetailsValue>{createActionItemDto?.description}</DetailsValue>
                    <DetailsValue>
                        {toDateAndTimeFormat(createActionItemDto?.startDate.toString() || "")}
                    </DetailsValue>
                    <DetailsValue>
                        {toDateAndTimeFormat(createActionItemDto?.dueDate.toString() || "")}
                    </DetailsValue>
                </Col>
            </Row>
        </ContentContainer>
    );

    const buildOrganisationalNodeAssociationModalBody = (): ReactElement<HTMLDivElement> => {
        return (
            <>
                <SbAlert
                    variant={"primary"}
                    text={t("ActionItemToHierarchyReportingNotificationMessage", {
                        keyPrefix: ActionItems,
                    })}
                />

                {renderActionItemDetails()}

                <LargeVerticalSpace />
                <AssignToHierarchyComponent
                    isNewActionItem={true}
                    nodeAssociationMode={ActionItemNodeAssociationMode.Organisational}
                    selectedNode={selectedNode}
                    setSelectedNode={setSelectedNode}
                />

                <LargeVerticalSpace />
                <EndAlignedDiv>
                    <Col sm={"auto"}>
                        <AssociateButton onClick={handleSaveActionItem} disabled={!selectedNode} />
                    </Col>
                    <Col sm={"auto"}>
                        <CancelButton onClick={() => setAssociateNodeModal(false)} />
                    </Col>
                </EndAlignedDiv>
            </>
        );
    };

    const buildOrganisationalNodeAssociationModalTitle = (): ReactElement<HTMLDivElement> => {
        return (
            <>
                <PageHeading>{t("AssociateNode", { keyPrefix: Nodes })}</PageHeading>
                <PageSubHeading>
                    {t("AssociateNodeHelpTextActionItemOrganisational", {
                        keyPrefix: ActionItems,
                    })}
                </PageSubHeading>
            </>
        );
    };

    return (
        <>
            <SbModal
                title={buildOrganisationalNodeAssociationModalTitle()}
                body={buildOrganisationalNodeAssociationModalBody()}
                isVisible={showAssociateNodeModal}
                updateIsVisible={setAssociateNodeModal}
                size={ModalSize.Large}
            />

            <PageHeading>{t("ActionItemCreate")}</PageHeading>
            <SectionVerticalSpace />
            <StyledHiddenOverflowForm onSubmit={handleShowAssociateNodeModal}>
                {isQuerySuccessful(actionItemDetails) && renderActionItemDetail()}
                <SectionVerticalSpace />
                {areQueriesSuccessful([
                    actionItemDetails,
                    getActionItemTypes,
                    getActionItemSubTypes,
                ]) && renderActionItemTypes()}
                <SectionVerticalSpace />

                {areQueriesSuccessful([actionItemDetails, getAdditionalFieldValues]) &&
                    getAdditionalFieldValuesResponseData &&
                    getAdditionalFieldValuesResponseData.length > 0 &&
                    (actionItemTypeNode?.nodeId === null ||
                        actionItemTypeNode?.nodeId ===
                            actionItemDetailsResponseData!.actionItemTypeNodeId) &&
                    renderExistingAdditionalInformation()}

                {getAdditionalFieldsResponseData &&
                    getAdditionalFieldsResponseData.length > 0 &&
                    actionItemTypeNode?.nodeId !==
                        actionItemDetailsResponseData!.actionItemTypeNodeId &&
                    areQueriesSuccessful([actionItemDetails, getAdditionalFields]) &&
                    renderAdditionalInformation()}

                {isQuerySuccessful(getRaciSetUsers) && renderRaciParticipants()}

                <SectionVerticalSpace />
                {isQuerySuccessful(actionItemDetails) && renderOptionalInformation()}

                <SectionVerticalSpace />
                {areQueriesSuccessful([actionItemDetails, getNotificationTriggers]) &&
                    renderNotificationTriggers()}

                <SectionVerticalSpace />
                {isQuerySuccessful(actionItemDetails) && renderActionButtons()}
            </StyledHiddenOverflowForm>
        </>
    );
};

export default CloneActionItemContainer;
