import { TFunction } from "i18next";
import {
    ActionItems,
    ChecklistAnswers,
    ChecklistQuestions,
    Common,
    Email,
    NodeTypeValues,
    Users,
} from "../../core/constants/translation-namespace";
import {
    actionItemsFields,
    answerFields,
    commonFields,
    emailFields,
    questionFields,
    userFields,
} from "../../core/utilities/data-table-fields";
import { autoCapitalize } from "./auto-capitalize";

export const translateText = (t: TFunction, value: string, keyPrefix?: string): string => {
    if (!value) return "";
    const capitalizedValue = autoCapitalize(value);
    const trimmedText = formattedText(capitalizedValue);

    if (commonFields.includes(trimmedText)) return t(trimmedText, { keyPrefix: Common });

    if (emailFields.includes(trimmedText)) return t(trimmedText, { keyPrefix: Email });

    if (questionFields.includes(trimmedText))
        return t(trimmedText, { keyPrefix: ChecklistQuestions });

    if (userFields.includes(trimmedText)) return t(trimmedText, { keyPrefix: Users });

    if (answerFields.includes(trimmedText)) return t(trimmedText, { keyPrefix: ChecklistAnswers });

    if (actionItemsFields.includes(trimmedText)) return t(trimmedText, { keyPrefix: ActionItems });

    if (trimmedText === "EscalationL1Time")
        return t("EscalationL1MinutesFromBaseStart", { keyPrefix: ChecklistQuestions });

    if (trimmedText === "EscalationL2Time")
        return t("EscalationL2MinutesFromBaseStart", { keyPrefix: ChecklistQuestions });

    if (trimmedText === "EscalationL3Time")
        return t("EscalationL3MinutesFromBaseStart", { keyPrefix: ChecklistQuestions });

    if (trimmedText === "HierarchyLevelValue")
        return t("NodeTypeValue", { keyPrefix: NodeTypeValues });

    if (keyPrefix) {
        return t(trimmedText, { keyPrefix: keyPrefix })?.includes(`${keyPrefix}.`)
            ? value
            : t(trimmedText, { keyPrefix: keyPrefix });
    }
    return value;
};

const formattedText = (value: string): string => {
    return value?.replaceAll(/[^A-Z0-9]+/gi, "");
};
