export const commonFields = [
    "Actions",
    "IsDeleted",
    "LastModifiedBy",
    "DateLastModified",
    "Description",
    "Category",
    "Path",
    "Select",
    "Show",
    "Hide",
    "IsEnabled",
    "Value",
    "Name",
    "SubType",
    "ValueType",
    "IsRequired",
    "Default",
    "Rating",
    "Status",
    "Details",
    "DateCreated",
    "CreatedBy",
    "Error",
    "UploadFileName",
    "DateCompleted",
];

export const emailFields = [
    "DateSent",
    "TimeSent",
    "Sent",
    "Subject",
    "Recipients",
    "FailedToSend",
];

export const questionFields = [
    "Checklist",
    "Question",
    "Comment",
    "OpenDateTime",
    "NestedQuestions",
    "StatusDate",
];

export const userFields = ["User"];

export const answerFields = ["Answer", "AnswerStatus"];

export const actionItemsFields = ["ActionItemDueDate", "AttachedEvidence"];
